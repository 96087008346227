window.byid = function ( id ) { return document.getElementById( id ) }
window.bycl = function ( id ) { return document.getElementByClassName( id ) }
window.query = window.q = function ( v, base ) { return ( base || document ).querySelector( v ) }
window.queryAll = function ( v, base ) { return ( base || document ).querySelectorAll( v ) }

// move this into a .js file and import it properly, depends how often we use it?
import { FetchRequest } from '@rails/request.js' //https://github.com/rails/request.js
window.bring = async function ( kind, url, options = {} ) {
  if ( !options['noturbo'] ) options['responseKind'] = 'turbo-stream' // default to turbo

  const request = new FetchRequest( kind, url, options )
  const response = await request.perform()
  // console.log( "response is", response )

  // run after the turbo-stream does?  How much time is needed?  was 100
  if ( response.ok ) {
    if ( options['ok'] ) setTimeout( () => { options['ok'].call( null, response ) }, 30 )

  } else {
    // error handling, 1st arg is 'this'
    if ( options['fail'] ) {
      options['fail'].call( null, response )
    } else {
      const msg = await response.text
      byid( 'flash' ).innerHTML = `<h2>Error: ${response.statusCode}</h2><pre>${msg.substring( 0, 1200 )}</pre>`
    }
  }
  return response
}

window.enter_submit = function ( e ) {
  if ( e.code == 'Enter' && !e.shiftKey && !e.ctrlKey ) {
    // console.log( 'submtiting from enter', e )
    e.preventDefault()
    e.target.closest( 'form' ).requestSubmit()
    return false
  }
}

// also have to put in turbo_stream_actions_helper
//https://marcoroth.dev/posts/guide-to-custom-turbo-stream-actions
// render turbo_stream: turbo_stream.js( "$('textarea').val('');$('.btn-orange').val('Update all')" )
import { StreamActions } from "@hotwired/turbo"


StreamActions.js = function () {
  const text = this.getAttribute( "text" )
  eval( text ) //console.log( message )
}
StreamActions.check_convo = function () {
  const text = this.getAttribute( "text" );
  if ( typeof cc != "undefined" ) cc.check_convo( text );
}
StreamActions.redirect = function () {
  const text = this.getAttribute( "text" );
  console.log( "stream.redirect", text )
  Turbo.visit( text );
}

// if we use .templateContent, which is tempting, it returns a documentFragment which is a huge PITA to work with besides appending
// update #flash & scrollTo it?
// ideally would re-render the form showing which rows have errors & why
// can access this from this.templateContent
// or pass in templateargument
// fl.innerHTML = `<div class="${this.getAttribute( 'kind' )}">${this.getAttribute( "text" )}</div>`
StreamActions.flash = function () {
  let fl = document.getElementById( 'flash' )
  let er = document.createElement( 'div' )
  er.className = this.getAttribute( 'kind' )
  er.appendChild( this.templateContent )
  fl.replaceChildren( er )

  // fl.innerHTML = `<div class="${this.getAttribute( 'kind' )}"></div>`
  // fl.firstChild.appendChild( this.templateContent )
  fl.scrollIntoView( { behavior: 'smooth' } )
}

// StreamActions.toggle = function () {
//   const cl = this.getAttribute( "class" )
//   const targets = this.getAttribute( "targets" )
//   console.log( "toggle", cl, targets )
//   $( targets ).toggleClass( cl )
// }