import { Controller } from "@hotwired/stimulus"
// attached to every page, creates the observer which saves poems seen, sync state with site, [target_uid, poem_id, date, source]
// website pushes those for target_uid most recent 100 (if they're gold), else just increments hits in counts db
// save recent hits in localstorage or a more-complicated db?
// localstorage = keep a string of the last poem_ids recently seen joined with a comma

// new views = push onto our list,

// or we could have each _sub create their own intersection Observer?

// Stimulus calls initialize() once per controller, and connect() every time the controller is connected to the DOM
// so we could create the observer with initialize then connect() to add this object to it
// visibilitychange: This event fires with a visibilityState of hidden when a user navigates to a new page, switches tabs, closes the tab, minimizes or closes the
export default class extends Controller {
  static targets = ["item"]

  initialize() {
    window.mylog = this
    console.log( "logs initialized" ) // called every page change annoyingly
    if ( !window.log_observer ) {
      window.log_observer = this.setup_observer()
      window.logs = []
      // mobile devices don't seem to call this...
      this.log_visible = document.addEventListener( 'visibilitychange', ( e ) => {
        if ( document.visibilityState === "hidden" ) this.push_actual()
      } )
      this.log_beforeunload = document.addEventListener( 'beforeunload', ( e ) => {
        console.log( "beforeunload push logs" )
        this.push_actual()
      } )
    }
  }
  disconnect() { window.log_observer.disconnect() } // console.log( "disconnect" );// removes all observations

  itemTargetConnected( ele ) {
    console.log( "watching new div", ele )
    window.log_observer.observe( ele )
  }

  itemTargetDisconnected( ele ) {
    window.log_observer.unobserve( ele )
  }

  // this never runs I don't think...
  // since we have multiple controller instances for each poem, use/trigger a global setTimeout so only 1 runs?
  schedule_push() {
    // console.log( "log_controller.js calling push()" )
    if ( window.push_timer ) clearTimeout( window.push_timer )
    window.push_timer = setTimeout( this.push_actual, 1500 ) // mostly via beforeunload/tab away. Lowered to 1.5 seconds for now... this server can handle it
  }

  // [ [target_id, item_id, url], ... ]
  push_actual() {
    // get/reset logs, load localstorage of recent hits to avoid dupes & filter
    let logs = window.logs
    window.logs = []
    let hist = localStorage['log_hist'] || ''
    let seen = new Set( hist.split( ',' ) )
    let to_log = logs.filter( ( v ) => { return !seen.has( v[1] ) } )
    if ( to_log.length == 0 ) return console.log( "skipping push, no new logs" )

    // upload
    var form_data = new FormData()
    form_data['mtok'] = $.cookie( 'tok' )
    for ( var idx in to_log ) { form_data.append( 'r[]', to_log[idx].join( ',' ) ) }
    // to_log.forEach( ( row, idx ) => { console.log( row ); form_data.append( 'r[]', row.join( ',' ) ) )
    console.log( `sending ${to_log.length} logs` )
    navigator.sendBeacon( "/log/add", form_data )

    // append ids list to hist + truncate to last Xchars, save to localStorage
    // trying just storing the last 2 for now, if it's OK we can just simplify to not repeating the same poem repeatedly
    // it'll let folks spike 'hits' count but who really cares?
    let new_hist = hist + ',' + to_log.map( ( v ) => { return v[1] } ).join( ',' )
    try { localStorage['log_hist'] = new_hist.slice( -12 ) } catch { } // incognito safari throws error. was -90

    // if we're a guest and on a single poem, push right away... sometimes 
  }
  // even the shortest poem is 9 characters for the ID 

  // commented( id ) {
  //   row = window.logs.find( ( a ) => a[1] == id )
  //   if ( row ) row[4] = 1
  // }

  // it's not triggering this ever?
  setup_observer() {
    // I think this gets only the first element of a destructured array?
    return new IntersectionObserver( ( [entry] ) => {
      let e = entry.target
      if ( entry.isIntersecting ) {
        // this doesn't print very often, though it's added somehow?
        console.log( "viewed", e )
        window.log_observer.unobserve( e )
        if ( e.dataset.u == user.id ) return // don't log ourselves
        let from = e.dataset.referrer ? window.t_referrer2 : window.location.pathname + window.location.hash.replace( /,/, '-' )
        // if it's not in our cache of logs seen, add it
        if ( !window.logs.find( ( a ) => a[1] == e.dataset.id ) )
          window.logs.push( [e.dataset.u, e.dataset.id, from, Math.floor( new Date().getTime() / 1000 )] )
        this.schedule_push()
      }
    }, { threshold: 0.9 } )
  }
}